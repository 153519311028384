.App.ScheduleYourService {
    .zipCode {
        $white:#ffffff;
        $card-bg:#f5f5f6;
        $color-lightgrey:#b9b9be;
        $color-grey:#696972;
        $color-darkgrey:#454550;
        $color-ink:#171725;
        $input-border:#d0d0d4;
        $input-border-hover:$color-lightgrey;
        $color-label:$color-grey;
        $color-input:$color-ink;
        $color-link:#0015ea;
        $color-error:#931621;
        $color-greyout:#acacac;
        $color-success:#3e8914;
        $disabled-bg:#d0d0d4;
        $disabled-text:$color-darkgrey;
        $margin:32px;
        $bradius:7px;

        line-height: 100%;
    
        .product-options-header {margin-bottom:8px; font-family: "ProximaNova-Extrabld", sans-serif; font-size: 16px; font-weight: 700;}
        .text-error {color:$color-error;}
        .text-success {color: $color-success !important;}
    
        .product-options-card {margin-bottom:$margin; padding:$margin/2; background: $card-bg; border-radius: $bradius*2;
            .form-group {margin:0px;}
        }
        .product-options-zip-input {padding:16px 16px 8px 16px; background: #ffffff; border-radius: $bradius $bradius 0px 0px; border-bottom:2px solid $input-border;
            &.Invalid {border-bottom:2px solid $color-error !important;}
            &:hover, &:active, &:focus {border-bottom:2px solid $input-border-hover;}
            & label {display:block; margin:0px; background: none; border:none; color: $color-label; font-size: 16px; font-weight: normal; line-height: 100%;}
            & input {padding:0px; height: 30px; background: none; border:none; color: $color-input; font-size: 18px;}
            & input, & input:active, & input:focus {color: $color-input; outline: none; border:none; box-shadow:none;}
        }
        .product-options-zip-info {margin-bottom:$margin/2;
            &::before, &::after {display: table; width: 100%; content: "";}
        }
        .product-options-zip-status {margin-top:$margin/4; margin-bottom:$margin/4; font-size: 30px; text-align: center;
            .fa, .fab, .fal, .far, .fas {display:block; margin-left: auto; margin-right: auto;}
        }
        .product-options-zip-status-text {padding:$margin/4;}
        .product-options-zip-status-text > p { font-family: ProximaNova-Extrabld, sans-serif; font-size:16px; font-weight: 700; text-align: center;}
        .product-options-zip-submit {text-align: right; color: $color-link; font-size: 16px; font-family: ProximaNova-Extrabld, sans-serif; font-weight: 700;
            span {cursor:pointer;}
        }
        .product-options-zip-message {min-height: $margin;
            .text-error {margin-top:4px; font-size: 14px; line-height: 100%;}
        }

        .btn {display:block; margin-top:$margin; padding:9px; width:160px; max-width: 100%; border:1px solid $color-link; background: $color-link; border-radius: 19px; color: $white; font-family: "Proxima Nova Extrabld", sans-serif; font-size: 16px; font-weight: 700; text-transform: none; letter-spacing: normal; line-height: 125%;
            &, &.disabled, &:disabled {opacity: 1;}
            &.disabled, &:disabled {border:1px solid $disabled-bg; background: $disabled-bg; color: $disabled-text; cursor: not-allowed;}
        }
    
        .form-group + button[type="submit"] {margin-bottom:$margin; margin-left: auto; margin-right: auto;}
    
        .product-options-message-trigger {color:$color-lightgrey; font-size: 20px;
            .fa, .fab, .fal, .far, .fas {margin-bottom: $margin/4; padding:$margin/4; cursor: pointer;}
        }
    
        .product-options-group {
            .product-options-message-trigger {
                .fa, .fab, .fal, .far, .fas {margin-bottom: $margin/2;}
            }
            .product-options-message-extra-info {display:block; color: $color-grey; font-size: 12px; font-family: "Proxima Nova Medium",Karla,sans-serif; font-weight: normal;}
        }
    
        .product-options-message {text-align: center; color: $color-grey; font-size: 12px; font-family: "Proxima Nova Medium",Karla,sans-serif;
            .product-options-message-trigger {}
        }
    }
}