$color-blue: #0015ea;
$color-black: #000;
$color-white: #fff;
$bg-color-light: #f5f3f3;
$border-color: #ddd;
$error-msg: #b61428;
$input-bg: #eee;

@font-face {
  font-family: proximanova-regular;
  src: local("proximanova-regular"), url(../fonts/proximanova-regular.otf);
}

@font-face {
  font-family: proximanova-semibold;
  src: local("proximanova-semibold"), url(../fonts/proximanova-semibold.otf);
}

@font-face {
  font-family: proximanova-extrabold;
  src: local("proximanova-extrabold"), url(../fonts/proximanova-extrabold.otf);
}

.App.ScheduleYourService {
  line-height: 150%;
  $grid-gutter-width: 40px !default;
  @import "bootstrap/scss/functions";
  @import "bootstrap/scss/variables";
  @import "bootstrap/scss/mixins";
  @import "bootstrap/scss/root";
  @import "bootstrap/scss/reboot";
  @import "bootstrap/scss/code";
  @import "bootstrap/scss/grid";
  @import "bootstrap/scss/utilities/spacing";
  @import "bootstrap/scss/buttons";
  @import "bootstrap/scss/transitions";
  @import "bootstrap/scss/card";
  @import "bootstrap/scss/type";
  @import "bootstrap/scss/utilities/text";

  padding: 0 30px 30px 30px;

  .list-none {
    list-style-type: none;
  }
  .pos-relative {
    position: relative;
  }
  .disp-inline {
    display: inline;
  }
  .bl-1 {
    border-left: 1px solid $border-color;
  }
  .bb-1 {
    border-bottom: 1px solid $border-color;
  }
  .br-4 {
    border-radius: 4px;
  }
  .br-30 {
    border-radius: 30px !important;
  }
  .clr-white {
    color: $color-white !important;
  }
  .text-blue {
    color: $color-blue;
  }
  .color-blue {
    background-color: $color-blue !important;
  }
  .text-small {
    font-size: 12px;
  }
  span.text-right {
    float: right;
  }
  .pointer-cursor {
    cursor: pointer;
  }

  main {
    &,
    .container {
      min-height: 100vh;
    }
  }
  .height-inherit {
    min-height: inherit;
  }
  .bg-color-light {
    background-color: $bg-color-light;
  }

  .copy-above-cal-wrap {
    font-size: 13px;
    margin-top: 24px;
    margin-bottom: 30px;
  }

  .custom-input {
    border: none;
    padding: 10px;
    height: 100%;
    background: $input-bg;
    width: 100%;
  }
  .slots-radio-btn {
    position: absolute;
    right: 10px;
  }
  .alert-div {
    position: fixed !important;
    right: 0;
    z-index: 999 !important;
  }
  .no-slots-available-msg {
    color: $error-msg;
  }
  .spinner-wrapper {
    text-align: center;
    height: 500px;
    padding-top: 150px;
  }

  .DayPicker-Day {
    line-height: normal;
  }
  .DayPicker-Day--selected {
    background-color: $color-blue !important;
  }

  .below-calendar {
    margin-top: 32px;
  }

  .display-date-summary-header {
    background-color: $color-black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $color-white;
  }
  .display-date-summary-body {
    background-color: $input-bg;
  }

  .order-summary-items {
    position: relative;
    background-color: $color-white;
  }
  .schedule-note {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border-color: rgba(0, 0, 0, 0.125);
    padding: 10px 15px;
  }
  #TechDeliveryWrapper {
    text-align: center;
    margin: 0 auto 10px auto;
  }
  
  #Continue {
    width: 100px;
  }
  #Continue:disabled {
    background-color: #777 !important;
    border-color: #555;
  }
  .error {
    color: $error-msg;
  }
  .arrival-window-message {
    font-weight: bold;
    font-size: 15px;
  }

  .breadcrumb {
    padding: 0;
    margin: 0;
    border: 0;
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    display: flex;
  }
  .breadcrumb li {
    list-style: none;
    display: flex;
    align-items: center;
  }
  .breadcrumb .separator {
    margin: 0 5px;
    font-size: 14px;
    display: inline-block;
  }

  .items-list {
    background-color: white;
    position: relative;
    padding: 0;
    margin: 10px 0;
    li {
      list-style: none;
      padding: 5px 10px;
    }
    .item-wrapper {
      width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      display: flex;
      font-size: 14px;
      line-height: 20px;
      flex-wrap: nowrap;
      height: 100%;
      .item-text {
        flex-grow: 1;
        .item-title {
          font-weight: 700;
        }
        .item-discount-text{
          font-weight: 400;
          color: #555;
          text-transform: uppercase;
        }
      }
      .item-price {
        text-align: right;
        flex-grow: 3;
        .item-discount-price {
          text-decoration: line-through;
          color: #555;
        }
      }
    }
  }

  .checkout-terms-link {
    margin: 32px 0 0 0;
    text-align: left;
  }

  .checkout-terms-link p {
    font-family: proximanova-regular, sans-serif;
    font-size: 14px;
    line-height: 16px;
  }

  .consent-to-call {
    margin: 0 0 12px 0;
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .consent-to-call p {
    font-size: 14px;
    line-height: 16px;
  }

  .consent-to-call input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  .consent-to-call label {
    font-family: proximanova-regular, sans-serif;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 13px;
    position: relative;
    display: inline-block; 
    padding-left: 26px; /*16px width of fake checkbox + 10px distance between fake checkbox and text*/
  }

  .consent-to-call label::before,
  .consent-to-call label::after {
    position: absolute;
    content: "";
    display: inline-block; /*Needed for the line-height to take effect*/
  }

  /*Outer box of the fake checkbox*/
  .consent-to-call label::before{
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #eee;
    left: 0px;
    top: 3px;
  }

  /*Checkmark of the fake checkbox*/
  .consent-to-call label::after {
    height: 7px;
    width: 11px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    transform: rotate(-45deg);
    left: 5px;
    top: 8px;
  }

  /*Hide the checkmark by default*/
  .consent-to-call input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  .consent-to-call input[type="checkbox"]:checked + label::after {
    content: "";
  }

  .consent-to-call input[type="checkbox"]:checked + label::before,
  .consent-to-call input[type="checkbox"]:checked:hover + label::before {
    background-color: #0015EA;
  }  
  
  .consent-to-call input[type="checkbox"]:hover + label::before {
    background-color: #ccc;
  }
}
